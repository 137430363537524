/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Mohamed Muzamil",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Mohamed Muzamil",
    type: "website",
    url: "",
  },
};

//Home Page
const greeting = {
  title: "Mohamed Muzamil",
  logo_name: "Mohamed Muzamil",
  nickname: "v.Ro",
  subTitle:
    "To enhance my expertise in computer fields, especially information security, while leveraging my skills in communication, teamwork, and problem-solving in a dynamic environment.",
  resumeLink:
    "https://drive.google.com/file/d/13-auNuSivt1j1PMvieXXbK364b_U4-ki/view",
  portfolio_repository: "https://github.com/7Dvro",
  githubProfile: "https://github.com/7Dvro",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/7Dvro",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/mohamed-elrais-257ab1241/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  // {
  //   name: "YouTube",
  //   link: "https://www.youtube.com/@vro6167",
  //   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
  //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  {
    name: "Gmail",
    link: "mailto:mohemadmuzamil@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "X-Twitter",
  //   link: "https://x.com/vRo_1_",
  //   fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
  //   backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  // },
  // {
  //   name: "Facebook",
  //   link: "https://www.facebook.com/mohamed.muzamel.54/",
  //   fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
  //   backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  // },
  // {
  //   name: "Instagram",
  //   link: "https://www.instagram.com/v.ro_1_/",
  //   fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
  //   backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  // },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React-Redux",
        "⚡ Developing mobile applications using Flutter, React Native and solo android apps using Kotlin",
        "⚡ Creating application backend in Node, Express & Sanity",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#4b8beb",
          },
        },
        {
          skillName: "Sanity",
          fontAwesomeClassname: "simple-icons:sanity",
          style: {
            color: "#eb664b",
          },
        },
        {
          skillName: "Odoo",
          fontAwesomeClassname: "simple-icons:odoo",
          style: {
            color: "#7d2fc2",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Deploying deep learning models on cloud to use on mobile devices",
        "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
      ],
    },
    {
      title: "InfoSec & Penetration Testing",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Identify and Assess Vulnerabilities: Conduct thorough testing to discover weaknesses in systems, networks, and applications that could be exploited by attackers.",
        "⚡ Simulate Attacks: Perform controlled attacks to understand how vulnerabilities can be exploited in real-world scenarios, helping to identify and address security gaps.",
        "⚡ Report and Recommend: Document findings in detailed reports, including identified vulnerabilities, risk assessments, and actionable recommendations for improving security.",
      ],
      softwareSkills: [
        {
          skillName: "TryHackMe",
          fontAwesomeClassname: "simple-icons:tryhackme",
          style: {
            color: "#a32812",
          },
          profileLink: "https://tryhackme.com/p/mohemadmuzamil",
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "TryHackMe",
      iconifyClassname: "simple-icons:tryhackme",
      style: {
        color: "#a32812",
      },
      profileLink: "https://tryhackme.com/p/mohemadmuzamil",
    },
    {
      siteName: "CompTIA",
      iconifyClassname: "simple-icons:comptia",
      style: {
        color: "#e85207",
      },
      profileLink: "",
    },
    {
      siteName: "edX",
      iconifyClassname: "simple-icons:edx",
      style: {
        color: "#080300",
      },
      profileLink: "",
    },
    {
      siteName: "CISCO",
      iconifyClassname: "simple-icons:cisco",
      style: {
        color: "#0585fc",
      },
      profileLink: "",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Indian Institute of Information Technology Kurnool",
      subtitle: "M.S. in Computer Science",
      logo_path: "IUA Logo.png",
      alt_name: "IUA",
      duration: "2023 - Present",
      descriptions: [
        "⚡ I have undergone comprehensive training in key information security areas, including network security, cryptography, algorithms, and an in-depth exploration of operating systems..etc.",
       ],
      website_link: "https://iua.edu.sd/",
    },
    {
      title: "Alsalama collage of science and technology",
      subtitle: "B.Tech. in Information Technology",
      logo_path: "ACST.png",
      alt_name: "ACST",
      duration: "2018 - 2022",
      descriptions: [
       
        "⚡ I have completed extensive coursework in core IT subjects, including programming, algorithms, databases, operating systems, networking, information security, and artificial intelligence..etc.",
        "⚡ Apart from this, I also pursued specialized courses in Networking, Information Security, Data Science, Cloud Computing and Full-stack Software Development.",
        "⚡ I was honored to receive a Masters scholarship at the International University of Africa, where I also had the opportunity to serve as a teaching assistant in the School of Computer Studies. These experiences have allowed me to achieve numerous milestones in my academic and professional journey.",
      ],
      website_link: "https://lms.acst.edu.sd/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "CCNP Core(350-401)",
      subtitle: "- Network",
      logo_path: "cisco.png",
      certificate_link:
        "",
      alt_name: "CISCO",
      color_code: "#0585fc",
    },
    {
      title: "CompTIA Scurity+",
      subtitle: "- Scurity+",
      logo_path: "comptia.png",
      certificate_link:
        "",
      alt_name: "CompTIA",
      color_code: "#8C151599",
    },
    {
      title: "CISCO - CyberOps",
      subtitle: "- SOC",
      logo_path: "cisco.png",
      certificate_link:
        "",
      alt_name: "CISCO",
      color_code: "#0585fc",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "IT Manager, Data Analyst, and Web & Mobile App Development Specialist with expertise in information security, strategic planning, and site management. Committed to applying technical and managerial skills to thrive in dynamic work environments.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Server Management (CS College)",
          company: "International University of Africa.",
          company_url: "https://iua.edu.sd/",
          logo_path: "IUA Logo.jpg",
          duration: "January 2023 – Present",
          location: "GHJ9+933, Madani St, Khartoum 12223, Sudan",
          description:
            "Providing technical support at the college, managing the internal network and computer labs, and setting up a local server for student training.",
          color: "#9b1578",
        },
        {
          title: "Teaching Assistant",
          company: "International University of Africa.",
          company_url: "https://iua.edu.sd/",
          logo_path: "IUA Logo.jpg",
          duration: "January 2023 – Present",
          location: "GHJ9+933, Madani St, Khartoum 12223, Sudan",
          description:
            `• Teach programming principles and languages including Java and JavaScript.
             • Instruct on database management using MySQL.`,
          color: "#fc1f20",
        },
        {
          title: "IT Manager",
          company: "Alsifa Flour Mills.",
          company_url: "https://www.alshifamilling.com",
          logo_path: "afm.png",
          duration: "August 2023 – August 2024",
          location: "62VF+92, Al Manaqil, Sudan",
          description:
            "IT Manager, Data Analyst, Web and Mobile Application Development Specialist with expertise in Information Security, Strategic Planning and Website Management. Committed to applying technical and managerial skills to succeed in dynamic business environments.",
          color: "#000000",
        },
        {
          title: "HR Employee",
          company: "Alsifa Flour Mills.",
          company_url: "https://www.alshifamilling.com",
          logo_path: "afm.png",
          duration: "May 2024 – August 2024",
          location: "62VF+92, Al Manaqil, Sudan",
          description:
            "Completing and entering employee data into the electronic archive, creating a paper archive, and drafting questionnaires to support work development.",
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Programmer",
          company: "Mithaqq.",
          company_url: "https://www.facebook.com/p/%D8%B4%D8%B1%D9%83%D8%A9-%D9%85%D9%8A%D8%AB%D8%A7%D9%82-%D9%84%D8%AA%D8%B7%D9%88%D9%8A%D8%B1-%D8%A7%D9%84%D8%A3%D8%B9%D9%85%D8%A7%D9%84-100063652165295/",
          logo_path: "mithaqq.jpg",
          duration: "April 2019 – July 2019",
          location: "Al-Said Ali St, Khartoum North, Sudan",
          description:
            "Developing a desktop application for administrative systems in Java and providing technical support.",
          color: "#000000",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "IT Support",
          company: "The United Peace Organization",
          company_url: "",
          logo_path: "upo.jpeg",
          duration: "August 2024 - Present",
          location: "Q9PG+988, Wadi Halfa, Sudan",
          description:
            "IT Manager, Data Analyst, and Web & Mobile App Development Specialist with expertise in information security, strategic planning, and site management. Committed to applying technical and managerial skills to thrive in dynamic work environments.",
          color: "#4285F4",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects use a variety of advanced technology tools. My main expertise is in developing administrative systems, websites, web and mobile applications using cloud or on-premises infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
  //   {
  //     id: "Portfolio",
  //     name: "Neuro-Symbolic Sudoku Solver",
  //     createdAt: "2023-07-02T00:00:00Z",
  //     description: "Paper published in KDD KiML 2023",
  //     url: "https://arxiv.org/abs/2307.00653",
  //   },
  //   {
  //     id: "mdp-diffusion",
  //     name: "MDP-Diffusion",
  //     createdAt: "2023-09-19T00:00:00Z",
  //     description: "Blog published in Paperspace",
  //     url: "https://blog.paperspace.com/mdp-diffusion/",
  //   },
  //   {
  //     id: "consistency-models",
  //     name: "Consistency Models",
  //     createdAt: "2023-10-12T00:00:00Z",
  //     description: "Blog published in Paperspace",
  //     url: "https://blog.paperspace.com/consistency-models/",
  //   },
   ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "mhmd.jpg",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, AI, React, Android, Cloud and Opensource Development.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I like to document some of my experiences in professional career journey as well as some technical knowledge sharing.",
    link: "",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "GHJ9+933, Madani St, Khartoum 12223, Sudan",
    locality: "International University of Africa _ (IUA)",
    country: "sudan",
    region: "Muslam",
    postalCode: "12223",
    streetAddress: "Madani St",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/rW9Lz83eoXpXM4yr7",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
